import { ISectionBody } from "components/Section/Body";

interface DefaultBodyWrapperProps {
    singleView: boolean;
    backgroundSettings: ISectionBody['backgroundSettings'];
    hasBackground: boolean;
    children: React.ReactNode;
}

const DefaultBodyWrapper: React.FC<DefaultBodyWrapperProps> = ({
    singleView,
    backgroundSettings,
    hasBackground,
    children
}) => {
    const bodyBackgroundClass = hasBackground ? 'egt-section-body-with-background-image' : 'egt-section-body-without-background-image';
    const bodyColumnsClass = `egt_section_body_${singleView ? 'single_view' : 'multi_view'}`;

    return (
        <div
            style={!singleView ? backgroundSettings : {}}
            className={`egt_section_body ${bodyColumnsClass} ${bodyBackgroundClass}`}
        >
            {children}
        </div>
    );
};

export default DefaultBodyWrapper;