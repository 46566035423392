import styled from 'styled-components';
import { InfoBox } from './TournamentsInfoBox';

const ScoreboardContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 44px;
    width: 95%;
    z-index: 2;
    border: 1px solid #920523;
    border-radius: 10px;
    background-color: rgb(0 0 0 / 69%);
    padding: 0 6px;
    box-sizing: border-box;

    @media (min-width: 768px) {
        width: 60vw;
        height: 8vh;
        min-height: 60px;
    }
`;

const ScoreboardContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

interface TournamentsScoreboardProps {
    userId: string;
    score: string;
    position: string;
}

const TournamentsScoreboard = ({ userId, score, position }: TournamentsScoreboardProps) => {
    return (
        <ScoreboardContainer>
            <ScoreboardContent>
                <InfoBox
                    icon="/tournaments/icons/user"
                    title="ID"
                    value={userId}
                />
                <InfoBox
                    icon="/tournaments/icons/score"
                    title="Score"
                    value={score}
                />
                <InfoBox
                    icon="/tournaments/icons/position"
                    title="Position"
                    value={position}
                />
            </ScoreboardContent>
        </ScoreboardContainer>
    );
};

export default TournamentsScoreboard;
