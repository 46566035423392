import { ITournamentModalData } from '../../../types/ITournamentModalData';
import { useSettings } from '../../../contexts/SettingsContext';
import styled from 'styled-components';
import { MODAL_TYPES, OPEN_TYPES } from '@repo/common';
import Flexbox from 'components/shared/Flexbox';
import { useMemo } from 'react';
import Image from 'components/shared/Image';
import { TournamentLevels } from 'services/TournamentService/interfaces';

const ModalContainer = styled(Flexbox).attrs({
  $justify: 'center',
  $align: 'center',
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 98;
`;

const StyledBaseModal = styled(Flexbox)<{ $backgroundImage?: string }>`
  ${({ $backgroundImage }) => $backgroundImage && `background-image: url(${$backgroundImage});`}
  position: relative;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100% 100%;
`;

const InGameModal = styled(StyledBaseModal)`
  border-radius: var(--main-lobby-border-radius);
  border: 1px solid #c9c6c6;
  box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.97);
  overflow: hidden;
`;

const CloseIcon = styled(Image)`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 101;
`;

const BaseModal: React.FC<IModalProps> = ({ children, modalType, modalData, onClose }) => {
  const { openType } = useSettings();
  const isInGame = openType === OPEN_TYPES.INGAME;

  const modalStyles = useMemo(() => {
    switch (modalType) {
      case MODAL_TYPES.TOURNAMENT:
        if(modalData?.level === TournamentLevels.MADNESS){
          return { width: '316px', borderRadius: '16px' };
        } else {
          return { width: '316px', height: '238px', borderRadius: '16px' };
        }
      case MODAL_TYPES.INFO_TERMS:
        return { width: '100vw', height: '100vh', backgroundColor: '#2E2E2E' };
      default:
        return {};
    }
  }, [modalType, modalData?.level]);

  const borderStyles = useMemo(() => {
    const levelMap: Record<TournamentLevels, string> = {
      [TournamentLevels.GREEN]: '#00A619',
      [TournamentLevels.RED]: '#D40000',
      [TournamentLevels.MADNESS]: '#D40000',
      [TournamentLevels.PURPLE]: '#B041FF',
      [TournamentLevels.BLUE]: '#418DFF',
      [TournamentLevels.GOLD]: '#FFDF1A',
    };
  
    const levelKey = modalData?.level as TournamentLevels;
    return levelMap[levelKey]
      ? {
          border: `1px solid ${levelMap[levelKey]}`,
          boxShadow: `0px 0px 14.36px 0px ${levelMap[levelKey]}`,
        }
      : { border: 'none' };
  }, [modalData?.level]);

  const closeIconStyles = useMemo(() => {
    switch (modalType) {
      case MODAL_TYPES.INFO_TERMS:
        return {
          width: '32px',
          height: '32px',
          borderRadius: '6px',
          backgroundColor: '#48494B',
          padding: '4px'
        };
      default:
        return {};
    }
  }, [modalType]);

  const BaseModalComponent = isInGame ? InGameModal : StyledBaseModal;

  return (
    <ModalContainer>
      <BaseModalComponent style={{ ...modalStyles, ...borderStyles }} $backgroundImage={modalData?.backgroundImage}>
        <CloseIcon
          src={`${modalData?.closeIconSrc}`}
          onClick={onClose}
          manualAffix='.svg'
          style={{...closeIconStyles}}
        />
        {children}
      </BaseModalComponent>
    </ModalContainer>
  );
};

interface IModalProps {
  modalData: ITournamentModalData;
  modalType: string;
  children: React.ReactNode;
  onClose: () => void;
}

export default BaseModal;
