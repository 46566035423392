import styled, { css } from 'styled-components';
import { useApplication } from 'contexts/ApplicationContext';
import TournamentBadge from 'components/Tournaments/TournamentBadge';
import Flexbox from 'components/shared/Flexbox';

const HeaderBackground = styled.div<{ background: string }>`
    width: 309px;
    height: 64px;
    background-image: ${({ background }) => background};
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;
const gradientText = css`
    background: linear-gradient(
        to bottom,
        #fff439 20%,
        #fffda4 40%,
        #f9c419 50.33%,
        #c59029 78.5%,
        #b16c04 87%,
        #b16c04 90%,
        #fbce35 95.5%
    );
    background-clip: text;
    background-size: 100% 100%;
    background-position: 0 0;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.34px #fff439;
    filter: drop-shadow(0px 1.71px 1.5px rgba(0, 0, 0, 0.75));
`;
const HeaderContainer = styled.div`
    position: relative;
    line-height: 1;
    margin-top: 3px;
`;
const HeaderTitle = styled.div`
    ${gradientText}
    font-size: 16px;
    font-weight: 700;
`;
const HeaderAmount = styled(Flexbox)`
    ${gradientText}
    font-size: 35px;
    font-weight: 800;
`;
const Currency = styled.span`
    font-size: 7px;
    font-weight: 900;
    writing-mode: vertical-rl;
    text-orientation: upright;
`;

const ManyTournamentHeader = () => {
    const app = useApplication();
    const image = `url(${app.asset(`/assets/portal/tournaments/prizePool.webp`)})`;
    const tournamentLeftCoinsBadge = '/tournaments/coinsLeft';
    const tournamentRightCoinsBadge = '/tournaments/coinsRight';

    return (
        <HeaderBackground background={image}>
            <HeaderContainer>
                <TournamentBadge
                    src={tournamentLeftCoinsBadge}
                    position={{ bottom: '-13px', left: '-25px' }}
                    width="106px"
                    height="44px"
                    shouldScale={false}
                />
                <HeaderTitle>PRIZE POOL</HeaderTitle>
                <HeaderAmount $justify="center" $align="center">
                    9 000 000 <Currency>EUR</Currency>
                </HeaderAmount>
                <TournamentBadge
                    src={tournamentRightCoinsBadge}
                    position={{ bottom: '-13px', right: '-25px' }}
                    width="106px"
                    height="44px"
                    shouldScale={false}
                />
            </HeaderContainer>
        </HeaderBackground>
    );
};
export default ManyTournamentHeader;
