import { useTranslator } from "hooks/useTranslator";
import TournamentBadge from "../../TournamentBadge";
import TournamentsPrize from "../../TournamentsPrize";
import TournamentsScoreboard from "../../TournamentsScoreboard";
import { useMemo } from "react";
import TournamentDateContainer from "../../TournamentDateContainer/TournamentDateContainer";
import { IParsedTournament } from "services/TournamentService/interfaces";
import styled from "styled-components";

const titleTextStyle = `
    background-image: repeating-linear-gradient(to bottom, 
        #fd4c00 25%,
        #fff439 28%,
        #ffffed 40%,
        #ffff70 57%,
        #ffc10a 70%,
        #e78806 79%,
        #ffc10a 86.5%,
        #fff439 89.5%
    );
    background-size: 150%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.34px #fff439;
`;

const Title = styled.div`
    position: absolute;
    top: 8px;
    font-weight: 700;
    font-size: 23px;
    ${titleTextStyle}
    text-transform: uppercase;
    @media (min-width: 768px) {
        top: 10px;
        font-size: clamp(34px, calc(34px + 1vw), 72px);
    }
`;

const SoloTournamentComponent = ({ tournament }: { tournament: IParsedTournament }) => {
    const translate = useTranslator();
    const borderColor = tournament.level?.toLowerCase() || 'transparent';
    const cupBadgeWidth = 'clamp(37px, calc(37px + 0.5vw), 50px)';
    const cupBadgeHeight = 'clamp(37px, calc(37px + 0.5vw), 50px)';
    const periodBadgeWidth = 'clamp(50px, calc(50px + 0.5vw), 65px)';
    const periodBadgeHeight = 'clamp(50px, calc(50px + 0.5vw), 65px)';

    const tournamentCupBadge = '/tournaments/cupFlag';

    const tournamentPeriodBadge = useMemo(() => {
        return '/tournaments/tournamentBadge3Day';
        // return '/tournaments/tournamentBadge24h'
        // return '/tournaments/tournamentBadgeWeekly'
    }, []);


    return (
        <>
            <Title>{tournament.name}</Title>
            <TournamentsPrize amount="30 000" currency={tournament.baseCurrency} />
            <TournamentDateContainer tournament={tournament} borderColor={borderColor} shouldScale={true} />
            <TournamentBadge 
                src={tournamentCupBadge} 
                position={{ top: '0' }} 
                width={cupBadgeWidth}
                height={cupBadgeHeight}
            />
            <TournamentBadge
                src={tournamentPeriodBadge}
                position={{ top: '0', left: '0' }}
                width={periodBadgeWidth}
                height={periodBadgeHeight}
            />
            <TournamentsScoreboard userId="User 867" score="123 234 567" position="456 789" />
        </>
    )
}

export default SoloTournamentComponent;