import Image from 'components/shared/Image';
import styled from 'styled-components';

interface Position {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
    position?: 'fixed' | 'absolute';
}

interface WrapperProps {
    $position: Position;
}

interface Size {
    width?: string;
    height?: string;
}

interface BadgeImageProps {
    $size: Size;
    $rotation?: string;
}

const defaultPosition: Position = {
    top: '0',
    position: 'absolute'
};

const defaultSize: Size = {
    width: '37px',
    height: '37px'
};

const Wrapper = styled.div<WrapperProps>`
    position: ${({ $position }) => $position.position || 'absolute'};
    top: ${({ $position }) => $position.top};
    left: ${({ $position }) => $position.left};
    right: ${({ $position }) => $position.right ?? '7px'};
    bottom: ${({ $position }) => $position.bottom};
    z-index: 1;
    width: fit-content;

    picture {
        display: flex;
    }
`;

const BadgeImage = styled(Image)<BadgeImageProps>`
    width: ${({ $size }) => $size.width ?? '37px'};
    height: ${({ $size }) => $size.height ?? '37px'};
    transform: ${({ $rotation }) => $rotation ? `rotate(${$rotation})` : 'none'};
`;

interface TournamentBadgeProps {
    position?: Position;
    width?: string;
    height?: string;
    src: string;
    rotation?: string;
    shouldScale?: boolean;
}

const TournamentBadge: React.FC<TournamentBadgeProps> = ({ 
    position = defaultPosition, 
    width, 
    height, 
    src, 
    rotation,
    shouldScale = true 
}: TournamentBadgeProps) => {
    const size: Size = {
        width: width ?? defaultSize.width,
        height: height ?? defaultSize.height
    };

    return (
        <Wrapper $position={position}>
            <BadgeImage src={src} $size={size} $rotation={rotation} />
        </Wrapper>
    );
};

export default TournamentBadge;