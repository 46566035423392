import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { changeTournamentModalInLocalStorage, saveTournamentModalsIntoLocalStorage } from '../utils/tournamentHelpers';
import { MODAL_TYPES, LOBBY_SECTION_NAME, SECTION_NAMES } from '@repo/common';
import { ITournament, TournamentLevels } from '../services/TournamentService/interfaces';
import { IModal } from 'types/IModal';
import ITournamentModalInfo from 'types/ITournamentModalInfo';
import { ITermsInfoModalData } from 'types/ITermsInfoModalData';
import { ITournamentModalData } from 'types/ITournamentModalData';
import { getFromLocalStorage } from 'utils/helpers';
import { useApplication } from 'contexts/ApplicationContext';
import { useCallback, useEffect } from 'react';
import ISectionSettings from 'types/ISectionSettings';

const TERMS_INFO_MODAL_ID: string = "modal_info_terms";

export const modalsState = atom<IModal[]>({
  key: 'modalsState',
  default: [],
});

export const visibleModalState = atom<IModal | null>({
  key: 'visibleModalState',
  default: null,
});

export const madnessModalIdSelector = selector({
  key: 'madnessModalIdSelector',
  get: () => `modal_${TournamentLevels.MADNESS.toLowerCase()}`,
});

export const termsInfoModalIdSelector = selector({
  key: 'termsInfoModalIdSelector',
  get: () => TERMS_INFO_MODAL_ID,
});

export const tournamentModalsSelector = selector({
  key: 'tournamentModalsSelector',
  get: ({ get }) => {
    const modals = get(modalsState);
    return new Map(modals.map((modal) => [modal.modalId, modal]));
  },
});

export const useTournamentModals = () => {
    const [modals, setModals] = useRecoilState(modalsState);
    const app = useApplication();
    const madnessModalId = useRecoilValue(madnessModalIdSelector);
    //const { sections } = useGetSections();
  
    const parseTournamentModals = useCallback((tournaments: ITournament[], balanceCurrency: string) => {
      const tournamentModals = new Map<string, IModal>();
  
      tournaments.forEach((tournament: ITournament) => {
        if (tournament.showWelcomeModal) {
          const modal: IModal = {
            modalId: tournament.id,
            type: MODAL_TYPES.TOURNAMENT,
            data: {
              id: tournament.id,
              name: tournament.name,
              level: tournament.level,
              prizeFund: tournament.prizeFund,
              currency: balanceCurrency,
            } as ITournamentModalData,
          };
          tournamentModals.set(modal.modalId, modal);
        }
      });
      parseMadnessModal(tournaments, balanceCurrency, tournamentModals, app.store.lobbyStore.SECTIONS.value);
      return tournamentModals;
    }, [app.store.lobbyStore.SECTIONS]);
  
    const parseMadnessModal = (
      tournaments: ITournament[],
      currency: string,
      tournamentModals: Map<string, IModal>,
      sections: ISectionSettings[]
    ) => {
      const hasMadnessSection = tournaments.some(tournament => tournament.id === MODAL_TYPES.TOURNAMENT);
  
      if (!hasMadnessSection) {
        const tournamentsSection = sections.find(section => section.name === SECTION_NAMES.TOURNAMENTS);
  
        if (tournamentsSection && tournamentsSection.context?.showWelcomeModal) {
          const level = TournamentLevels.MADNESS;
          tournamentModals.set(madnessModalId, {
            modalId: madnessModalId,
            type: MODAL_TYPES.TOURNAMENT,
            data: {
              id: madnessModalId,
              name: tournamentsSection.context.headerTitle,
              level,
              prizeFund: Number(tournamentsSection.context.prizeFund),
              currency,
              closeIconSrc: '/icons/close',
              backgroundImage: '/assets/portal/group_tournament/default/cardsMADNESS.png'
            } as ITournamentModalData,
          });

          changeTournamentModalInLocalStorage('tournament_modals', {
            id: madnessModalId,
            shouldOpen: tournamentsSection.context?.showWelcomeModal,
          } as ITournamentModalInfo);
        }
      }
    };
  
    const initiateTournamentModals = () => {
      const tournamentsData = app.store.tournamentsStore.sortedTournaments.value as ITournament[];

      //saveTournamentModalsIntoLocalStorage(tournamentsData, madnessModalId);
  
      if (tournamentsData.length > 0) {
        const tournamentModals = parseTournamentModals(tournamentsData, app.store.balanceStore.BALANCE.value.currency);
        
        setModals((prevModals) => {
          const updatedModals = new Map(prevModals.map(m => [m.modalId, m]));
  
          // Merge new modals without duplicating
          tournamentModals.forEach((modal: IModal, modalId: string) => {
            updatedModals.set(modalId, modal);
          });
  
          return Array.from(updatedModals.values());
        });

        saveTournamentModalsIntoLocalStorage(tournamentsData, madnessModalId);
      }
    };
  
    return { modals, initiateTournamentModals };
};

export const useVisibleModal = () => {
  const [visibleModal, setVisibleModal] = useRecoilState(visibleModalState);
  const app = useApplication();
  const tournamentModalsInfo = getFromLocalStorage('tournament_modals');
  const tournamentModals = useRecoilValue(tournamentModalsSelector);

  const showActiveTournamentModal = () => {
    if (app.store.lobbyStore.SELECTED_SECTION.value === LOBBY_SECTION_NAME) {
      const modalToShow = tournamentModalsInfo.find((modal: ITournamentModalInfo) => modal.shouldOpen)?.id;
      if(modalToShow) {
        setVisibleModal(tournamentModals.get(modalToShow) || null);
      }
    }
  };

  useEffect(() => {
    showActiveTournamentModal();
  }, [tournamentModals]);

  return { visibleModal, showActiveTournamentModal };
};

export const useModalActions = () => {
    const setModals = useSetRecoilState(modalsState);
    const setVisibleModal = useSetRecoilState(visibleModalState);
    const termsInfoModalId = useRecoilValue(termsInfoModalIdSelector);
    const tournamentModals = useRecoilValue(tournamentModalsSelector);
    const madnessModalId = useRecoilValue(madnessModalIdSelector);
    const app = useApplication();
  
    const handleTournamentModalClosed = (modalId: string) => {
      changeTournamentModalInLocalStorage("tournament_modals", { id: modalId, shouldOpen: false } as ITournamentModalInfo);
  
      setModals((prevModals) => prevModals.filter(modal => modal?.modalId !== modalId && modal?.type === MODAL_TYPES.TOURNAMENT));
  
      const modalsInLocalStorage = getFromLocalStorage("tournament_modals") as ITournamentModalInfo[];
      setVisibleModal(null);
  
      const tournamentModalId = modalsInLocalStorage.find(modal => modal.shouldOpen)?.id;
      if (tournamentModalId) {
        setVisibleModal(tournamentModals.get(tournamentModalId) || null);
      }
    };
  
    const handleTermsInfoModalOpen = (terms: string) => {
        const infoModal: IModal = {
            modalId: "modal_info_terms",
            type: MODAL_TYPES.INFO_TERMS,
            data: {
                title: "Terms & Conditions",
                terms,
                closeIconSrc: "/ui/settings_close"
            } as ITermsInfoModalData
        };
    
        setModals(prevModals => {
            const filteredModals = prevModals.filter(modal => modal.modalId !== "modal_info_terms");
            return [...filteredModals, infoModal];
        });
    
        setVisibleModal(infoModal);
    };
  
    const handleTermsInfoModalClosed = () => {
      setModals(prevModals => prevModals.filter(modal => modal?.modalId !== termsInfoModalId && modal?.type !== MODAL_TYPES.INFO_TERMS));
      setVisibleModal(null);
    };
  
    const handleTournamentModalOpen = (modalId: string, setSelectedSection: (newSelectedSection: string) => void) => {
      if(modalId !== madnessModalId){
          app.store.tournamentsStore.activeTournamentId.next(modalId);
      }

      setSelectedSection(SECTION_NAMES.TOURNAMENTS);
      setVisibleModal(null);
      changeTournamentModalInLocalStorage("tournament_modals", { id: modalId, shouldOpen: false } as ITournamentModalInfo);
    };
  
    return {
      handleTournamentModalClosed,
      handleTournamentModalOpen,
      handleTermsInfoModalOpen,
      handleTermsInfoModalClosed,
    };
  };