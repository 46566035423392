import styled from 'styled-components';
import TournamentBadge from './TournamentBadge';

const prizeTextStyle = `
    background-image: repeating-linear-gradient(to bottom, 
        #FFF439 0%,
        #FFFDA4 28%,
        #F9C419 40%,
        #ffff70 57%,
        #C59029 70%,
        #B16C04 79%,
        #B16C04 86.5%,
        #FBCE35 89.5%
    );
    background-size: 150%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.34px #fff439;
`;

const Currency = styled.span<{ $shouldScale?: boolean }>`
    position: relative;
    font-size: 26px;
    font-weight: 700;
    font-family: var(--font-family-secondary);
    ${prizeTextStyle}

    @media (min-width: 768px) {
        ${({$shouldScale}) => $shouldScale && `
            font-size: clamp(20px, calc(20px + 1vw), 50px);
        `}
    }
`;

const PrizeContainer = styled.div<{ $shouldScale?: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-family: var(--font-family-secondary);
    font-weight: 800;
    line-height: 1;
    text-align: center;
    ${prizeTextStyle}

    font-size: 55px;

    @media (min-width: 768px) {
        ${({$shouldScale}) => $shouldScale && `
            font-size: clamp(55px, calc(55px + 2vw), 96px);
        `}
    }
`;

interface TournamentsPrizeProps {
    amount: string;
    currency: string;
    shouldScale?: boolean;
}

const TournamentsPrize = ({ amount, currency, shouldScale = true }: TournamentsPrizeProps) => {
    const prizeBadge = '/tournaments/badgePrizePool';
    const viewPortBasedTop = shouldScale ? 'calc(-140% - 15px)' : '-44px';
    const viewPortBasedRight = shouldScale ? '-36%' : '-10px';
    const viewPortBasedWidth = shouldScale ? 'clamp(60px, calc(60px + 1vw), 80px)' : '60px';
    const viewPortBasedHeight = shouldScale ? 'clamp(50px, calc(50px + 1vw), 70px)' : '50px';
    
    return (
        <PrizeContainer $shouldScale={shouldScale}>
            {amount}
            <Currency $shouldScale={shouldScale}>
                {currency}
                <TournamentBadge 
                    src={prizeBadge} 
                    width={viewPortBasedWidth} 
                    height={viewPortBasedHeight} 
                    position={{ 
                        top: viewPortBasedTop, 
                        right: viewPortBasedRight 
                    }} 
                    rotation='30deg' 
                    shouldScale={shouldScale}
                />
            </Currency>
        </PrizeContainer>
    );
};

export default TournamentsPrize;
