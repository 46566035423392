import { ISectionBody } from 'components/Section/Body';
import DefaultBodyWrapper from 'components/shared/DefaultBodyWrapper';
import TournamentsSingleView from './Views/SingleView/TournamentsSingleView';
import TournamentsMultiView from './Views/MultiView/TournamentsMultiView';
import { useTournamentService } from 'contexts/TournamentsContext';
import ISectionSettings from 'types/ISectionSettings';
import { IParsedTournament, ITournament } from 'services/TournamentService/interfaces';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { viewAtom } from 'atoms/viewAtom';

const TournamentBody: React.FC<{ data: ISectionBody }> = ({ data }) => {
    const { singleView = false, backgroundSettings = {}, hasBackground, data: sectionData } = data;
    const [, setView] = useRecoilState(viewAtom);
    const { selectedTournamentSection, selectedTournament, showLoader, tournaments } = useTournamentService();
    const mutatedData: ISectionSettings = {
        ...data.data,
        games: selectedTournament?.games as IParsedTournament['games'],
        name: data.data.name + selectedTournament?.id,
    };

    const isOneTournament = tournaments.length === 1;
    if (!selectedTournament) return null;
    useEffect(() => {
        setView(singleView ? 'single' : 'multi');
    }, [singleView]);

    return (
        <DefaultBodyWrapper
            singleView={singleView}
            backgroundSettings={backgroundSettings}
            hasBackground={singleView ? false : hasBackground}
        >
            {singleView ? (
                <TournamentsSingleView
                    showLoader={showLoader}
                    selectedTournamentSection={selectedTournamentSection}
                    selectedTournament={selectedTournament as IParsedTournament}
                    isOneTournament={isOneTournament}
                    mutatedData={mutatedData}
                    singleView={singleView}
                />
            ) : (
                <TournamentsMultiView
                    data={data}
                    tournaments={tournaments as IParsedTournament[]}
                    selectedTournament={selectedTournament as IParsedTournament}
                    isOneTournament={isOneTournament}
                    mutatedData={mutatedData}
                    singleView={singleView}
                />
            )}
        </DefaultBodyWrapper>
    );
};

export default TournamentBody;
