import BodyJackpotPanel from 'components/JackpotPanel';
import { ISectionBody } from '..';
import Grid from 'components/Section/Grid';
import DefaultBodyWrapper from 'components/shared/DefaultBodyWrapper';

const BodyComponent: React.FC<{ data: ISectionBody }> = ({ data }) => {
    const { singleView, backgroundSettings, hasBackground, data: sectionData } = data;

    return (
        <DefaultBodyWrapper
            singleView={singleView ?? false}
            backgroundSettings={backgroundSettings}
            hasBackground={hasBackground ?? false}
        >
            {Boolean(sectionData.jackpotName?.length) && (
                <BodyJackpotPanel singleView={singleView} sectionData={sectionData} />
            )}
            <Grid data={sectionData} singleView={singleView ?? false} />
        </DefaultBodyWrapper>
    );
};

export default BodyComponent;
