import styled from 'styled-components';

interface FlexboxProps {
  $direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  $justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  $align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  $wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  $gap?: string;
  $flex?: string;
  $width?: string;
  $height?: string;
}

const Flexbox = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'row'};
  justify-content: ${({ $justify }) => $justify || 'flex-start'};
  align-items: ${({ $align }) => $align || 'stretch'};
  flex-wrap: ${({ $wrap }) => $wrap || 'nowrap'};
  gap: ${({ $gap }) => $gap || '0'};
  flex: ${({ $flex }) => $flex || 'none'};
  width: ${({ $width }) => $width || 'auto'};
  height: ${({ $height }) => $height || 'auto'};
`;

export default Flexbox;
