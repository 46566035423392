import { ITournamentPrize } from 'services/TournamentService/interfaces';


/**
 * exposes a polling function and a stop one
 * @param fn the function that should be polled
 * @param interval interval for polling
 * @returns The value.
 */

export const poll = (fn: Function, interval: number) => {
    let isPolling = true;

    const stop = () => {
        isPolling = false;
    };

    const startPolling = () => {
        return new Promise((resolve, reject) => {
            const executePoll = async () => {
                if (!isPolling) {
                    return reject(new Error('Polling manually stopped'));
                }

                try {
                    await fn();
                    setTimeout(executePoll, interval);
                } catch (error) {
                    stop();
                    reject(error);
                }
            };

            executePoll();
        });
    };

    return { start: startPolling, stop };
}

export const timers: {
    JP_STAT_FETCH: ReturnType<typeof setInterval> | null;
    JP_STAT_UPDATE: ReturnType<typeof setInterval> | null;
    GAMES_CURRENT_PLAYERS_FETCH: ReturnType<typeof setInterval> | null;
} = {
    JP_STAT_FETCH: null,
    JP_STAT_UPDATE: null,
    GAMES_CURRENT_PLAYERS_FETCH: null,
};

export const clearTimer = (timer: ReturnType<typeof setInterval> | null) => {
    if (timer) {
        clearInterval(timer);
        timer = null;
    }
};

export const getPrizeText = (prizeItem: ITournamentPrize): string => {
    switch (prizeItem.type) {
        case 'MONEY':
            return prizeItem.name.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        case 'GIFT_SPIN':
            return prizeItem.name;
        default:
            return prizeItem.name;
    }
};

export const applyElementStyles = (openType: string, mode: string, element: HTMLElement | null): void => {
    if (!element || !openType || !mode) {
        return;
    }

    if (openType === 'fullscreen') {
        if (element.classList.contains('egt_react_lobby_ingame')) {
            element.classList.replace('egt_react_lobby_ingame', 'egt_react_lobby_fullscreen');
        } else {
            element.classList.add('egt_react_lobby_fullscreen');
        }
    } else {
        if (element.classList.contains('egt_react_lobby_fullscreen')) {
            element.classList.replace('egt_react_lobby_fullscreen', 'egt_react_lobby_ingame');
        } else {
            element.classList.add('egt_react_lobby_ingame');
        }
    }
    if (mode === 'desktop') {
        if (element.classList.contains('egt_react_lobby_mobile')) {
            element.classList.replace('egt_react_lobby_mobile', 'egt_react_lobby_desktop');
        } else {
            element.classList.add('egt_react_lobby_desktop');
        }
    } else {
        if (element.classList.contains('egt_react_lobby_desktop')) {
            element.classList.replace('egt_react_lobby_desktop', 'egt_react_lobby_mobile');
        } else {
            element.classList.add('egt_react_lobby_mobile');
        }
    }
};
/**
 * debounce functionality
 * @param fn the debounced function
 * @param interval interval for debouncing
 * @returns The value.
 */
export const debounce = (func: Function, wait: number) => {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: any) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
};

export const formatNumberWithSpaces = (number: string | undefined): string | undefined => {
    return number?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

/**
 * Saves data to local storage.
 * @param key The key under which the data should be stored in local storage.
 * @param value The value to save in local storage.
 * @returns The value.
 */
export const setToLocalStorage = (key: string, value: any): any => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
        const items = localStorage.getItem(key);
        if (items) {
            return JSON.parse(items);
        } else {
            return [];
        }
    } catch (error) {
        console.error("Failed to store in localStorage:", error);
    }
};

/**
 * Retrieves the data from local storage by key.
 * @param key The key under which the data is stored in local storage.
 * @param defaultValue The default value to return if there is no data in local storage.
 * @returns An array of objects from local storage or the default value.
 */
export const getFromLocalStorage = (key: string, defaultValue: any[] = []): any[] => {
    try {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
        console.error("Failed to get from localStorage:", error);
        return defaultValue;
    }
};

