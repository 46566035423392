import Image from 'components/shared/Image';
import styled from 'styled-components';

const ButtonContainer = styled.button`
    width: 139px;
    height: 26px;
    border-radius: 15px;
    background: linear-gradient(to bottom, #393939 0%, #000000 100%);
    border: none;
    position: relative;
    color: #fff;
    font-family: var(--font-family-primary);
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 15px;
        padding: 2px;
        background: linear-gradient(to bottom, #FFF439 0%, #FFFDA4 25%, #F9C419 50%, #C59029 75%, #B16C04 100%);
        -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
`;

const JoinImage = styled(Image)`
    position: absolute;
    top: 0;
    left: 4%;
    width: 11%;
    height: 100%;
`;

const JoinInButton = () => {
    return (
        <ButtonContainer>
            <JoinImage src="/tournaments/icons/JoinIn"  manualAffix='.svg' />
            Join In
        </ButtonContainer>
    )
}

export default JoinInButton;