import { MODAL_TYPES } from '@repo/common';
import TournamentsModal from '../../components/Tournaments/TournamentsModal';
import { ITournamentModalData } from '../../types/ITournamentModalData';
import TermsInfoModal from '../../components/Tournaments/TermsInfoModal';

const componentModalMap: { [key: string]: React.FC<any> } = {
  [MODAL_TYPES.TOURNAMENT]: TournamentsModal,
  [MODAL_TYPES.INFO_TERMS]: TermsInfoModal
  // Add other types of modals here...
};

const Modal: React.FC<IModalProps> = ({ modalType, modalData }) => {
  const ModalComponent = componentModalMap[modalType ? modalType : ""];

  if (!ModalComponent) {
      return null;
  }

  return (<ModalComponent modalType={modalType} modalData={modalData} />);
};

interface IModalProps {
  modalType: string | undefined;
  modalData: ITournamentModalData;
};

export default Modal;