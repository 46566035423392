import { ITermsInfoModalData } from '../../../types/ITermsInfoModalData';
import Modal from '../../../components/Modal/BaseModal';
import styled from 'styled-components';
import { useModalActions } from 'atoms/modalsAtom';
import Flexbox from 'components/shared/Flexbox';

const ModalContentWrapper = styled(Flexbox).attrs({
    $direction: 'column',
    $align: 'center',
    $justify: 'flex-start',
    $width: '100%',
    $height: 'calc(100vh - 50px);'
    })`
    overflow-y: auto;
    background-color: #262626;
    margin-top: 50px;
`;

const Title = styled.h3`
  width: 149.07px;
  font-size: 14px;
  font-weight: bold;
  line-height: 16.41px;
  text-align: center;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: var(--font-family-primary);
`;

const TermsText = styled.span`
  max-height: 100px;
  color: white;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  text-align: left;
  padding: 10px;
  font-family: var(--font-family-primary);
`;

const TermsInfoModal = ({ modalData, modalType }: ITermsInfoModalProps) => {
    const modalActions = useModalActions();

    return (
        <Modal onClose={() => modalActions.handleTermsInfoModalClosed()} modalData={modalData} modalType={modalType}>
            <ModalContentWrapper>
                <Title>{modalData.title}</Title>
                <TermsText dangerouslySetInnerHTML={{ __html: modalData.terms }} />
            </ModalContentWrapper>
        </Modal>
    );
};

interface ITermsInfoModalProps {
    modalData: ITermsInfoModalData;
    modalType: string;
};

export default TermsInfoModal;