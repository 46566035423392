import Image from 'components/shared/Image';
import React from 'react';
import styled from 'styled-components';
import Flexbox from 'components/shared/Flexbox';

interface InfoBoxProps {
  icon: string;
  value: string;
  title: string;
}

const InfoBoxContainer = styled(Flexbox)`
  border-radius: 8px;
`;

const IconWrapper = styled(Flexbox)`
  picture {
    display: flex;
  }
`;

const Icon = styled(Image)`
  width: 15px;
  height: 15px;
  margin-right: 8px;

  @media (min-width: 768px) {
    width: unset;
    height: unset;
  }
`;

const Title = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  margin-bottom: 6px;
  @media (min-width: 768px) {
    font-size: clamp(12px, calc(12px + 0.5vw), 24px);
  }
`;

const Value = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: #FFDF1A;
  white-space: nowrap;
  @media (min-width: 768px) {
    font-size: clamp(16px, calc(16px + 0.5vw), 32px);
  }
`;

export const InfoBox: React.FC<InfoBoxProps> = ({ icon, value, title }) => {
  return (
    <InfoBoxContainer $align="flex-end">
      <IconWrapper>
        <Icon src={icon} alt={title} manualAffix='.svg' />
      </IconWrapper>
      <Flexbox $direction="column">
        <Title>{title}</Title>
        <Value>{value}</Value>
      </Flexbox>
    </InfoBoxContainer>
  );
};

export default InfoBox;
