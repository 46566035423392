import React from 'react';

interface StringMapperProps {
  text: string;
  className?: string;
  letterClassName?: string;
  renderLetter?: (letter: string, index: number) => React.ReactNode;
}

const StringMapper: React.FC<StringMapperProps> = ({
  text,
  className = '',
  letterClassName = '',
  renderLetter,
}) => {
  return (
    <div className={className}>
      {text.split('').map((letter, index) => (
        renderLetter ? (
          renderLetter(letter, index)
        ) : (
          <div
            key={`${letter}-${index}`}
            className={letterClassName}
          >
            {letter}
          </div>
        )
      ))}
    </div>
  );
};

export default StringMapper;