import { useTranslator } from 'hooks/useTranslator';
import { ITournamentModalData } from '../../../types/ITournamentModalData';
import Modal from '../../../components/Modal/BaseModal';
import { useGetSections } from 'atoms/sectionsAtom';
import styled from 'styled-components';
import { useModalActions } from 'atoms/modalsAtom';
import { useRecoilValue } from 'recoil';
import { madnessModalIdSelector } from 'atoms/modalsAtom';
import Flexbox from 'components/shared/Flexbox';
import { useMemo } from 'react';
import Image from 'components/shared/Image';
import { TournamentLevels } from 'services/TournamentService/interfaces';

const ModalContentWrapper = styled(Flexbox)<{ $styles: string }>`
  ${({ $styles }) => $styles}
`;

const Title = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: 28px;
  font-weight: 900;
  line-height: 32.81px;
  letter-spacing: 0.05em;
  text-align: center;
  border-radius: 0.4px 0px 0px 0px;
  background: linear-gradient(180deg, #FD4C00 0%, #FFF439 4%, #FFFFED 28.33%, #FFFF70 47.5%, #FFC10A 70%, #E78806 79%, #FFC10A 86.5%, #FFF439 89.5%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.34px #FFF439;
  filter: drop-shadow(0px 1.71px 1.5px rgba(0, 0, 0, 0.75));
  text-transform: uppercase;
`;

const AmountPlaceholder = styled(Flexbox).attrs({
    $align: 'center',
  })`
    height: 56.68px;
    position: relative;
  
    img {
      width: 100%;
      height: 50px;
    }
`;

const Amount = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 33.39px;
  line-height: 39.12px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
`;

const Currency = styled.span`
    font-weight: 700;
    font-size: 50%;
    line-height: 19.56px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
`;

const Button = styled.button`
  height: 28px;
  background-color: #FFFFFF;
  border: none;
  width: 144px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ButtonText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
`;

const MadnessImage = styled(Image)`
  width: 395px;
  height: 70px;
  padding-top: 20px;
`;

const TournamentsModal = ({ modalData, modalType }: ITournamentsModalProps) => {
    const translate = useTranslator();
    const { setSelectedSection } = useGetSections();
    const modalActions = useModalActions();
    const madnessModalId = useRecoilValue(madnessModalIdSelector);

    const modalStyles = useMemo(() => {
      switch (modalData?.level?.toLowerCase()) {
          case TournamentLevels.MADNESS?.toLowerCase():
              return `
                  flex-direction: column;
                  justify-content: space-evenly;
                  align-items: center;
                  z-index: 100;
                  width: 316px;
                  height: 300px;
                  position: relative;
                  border-radius: 16px;
                  box-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
                  background-repeat: no-repeat;
                  background-position: 0% 0%;
                  background-size: 100% 100%;
              `;
          default:
              return `
                  flex-direction: column;
                  width: 316px;
                  height: 100%;
                  gap: 25px;
                  justify-content: center;
                  align-items: center;
              `;
      }
  }, [modalData?.level]);

    return (
        <Modal onClose={() => modalActions.handleTournamentModalClosed(modalData.id)} modalData={modalData} modalType={modalType}>
            <ModalContentWrapper $styles={modalStyles}>
                {modalData.id === madnessModalId &&
                    <MadnessImage src='/group_tournament/default/tournamentsMadness' manualAffix='.png' />
                }
                <Title>{modalData.name}</Title>
                <AmountPlaceholder>
                    <Image
                        src={`/group_tournament/default/placeholder${modalData?.level}`}
                        manualAffix='.png'
                    />
                    <Amount>
                        {modalData.prizeFund}
                        <Currency>{modalData.currency}</Currency>
                    </Amount>
                </AmountPlaceholder>
                <Button onClick={() => modalActions.handleTournamentModalOpen(modalData.id, setSelectedSection)}>
                    <ButtonText>{translate("OPEN")}</ButtonText>
                </Button>
            </ModalContentWrapper>
        </Modal>
    );
};

interface ITournamentsModalProps {
    modalData: ITournamentModalData;
    modalType: string;
};

export default TournamentsModal;
